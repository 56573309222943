<template>
  <div :class="[collapsed ? 'collapsed' : '']">
    <sidebar></sidebar>
    <div id="main">
      <navbar></navbar>
      <div class="layout-wrapper">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/layouts/Navbar";
import Sidebar from "@/layouts/Sidebar";
import { computed } from "vue";
import store from "@/store";
export default {
  name: "index",
  components: { Sidebar, Navbar },
  setup() {
    const collapsed = computed(() => {
      return store.state.sidebar.isCollapsed;
    });
    return {
      collapsed,
    };
  },
};
</script>

<style scoped></style>
